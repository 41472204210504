<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_countPlanList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- １段目-->
            <div class="search-autocomplete first-search-item">
              <!-- 取引先-->
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.supplierRequired]"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!-- 棚卸実施日From-->
              <v-text-field
                outlined
                dense
                v-model="countImplementationDateFrom"
                :label="$t('label.lbl_countImplementationDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="countImplementationDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate(true)" text x-small>
                <!-- 前日 -->
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate(true)" text x-small>
                <!-- 次日 -->
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!-- 棚卸実施日To-->
              <v-text-field
                outlined
                dense
                v-model="countImplementationDateTo"
                :label="$t('label.lbl_countImplementationDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <!-- カレンダー-->
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="countImplementationDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate(false)" text x-small>
                <!-- 前日-->
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate(false)" text x-small>
                <!-- 次日-->
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->

            <div class="search-autocomplete">
              <!-- 担当者-->
              <v-autocomplete
                dense
                v-model="managerSelected"
                :items="managerList"
                :label="$t('label.lbl_popDestinationNm')"
                :hint="setManager()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="search-autocomplete">
              <!-- 倉庫-->
              <v-autocomplete
                dense
                v-model="warehouseSelected"
                :items="warehouseList"
                :label="$t('label.lbl_warehouseNameCd')"
                :hint="setWarehouse()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <v-row>
            <!-- ２段目-->
            <div class="search-autocomplete first-search-item">
              <!-- ロケ-->
              <v-autocomplete
                dense
                v-model="locationSelected"
                :items="HeaderlocationGList"
                :label="$t('label.lbl_locationGroupName')"
                :hint="setLocation()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" id="btn-search" class="get-btn" @click="getApiList(true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div v-if="displayBtn" class="btn-area">
                <!--承認処理ボタン-->
                <v-btn
                  v-if="approveRole == 'true'"
                  color="primary"
                  id="btn-search"
                  class="other-btn"
                  @click="approval"
                >
                  {{ $t("btn.btn_approvalProcess") }}</v-btn
                >
                <!--棚卸表発行ボタン-->
                <v-btn
                  color="primary"
                  id="btn-search"
                  class="post-btn"
                  @click="clickCreateList()"
                  >{{ $t("btn.btn_countissue") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="530px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>

          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              style="transform: scale(2)"
            />
          </template>
          <!-- 詳細ボタン -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="openUpdateDelete(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <!-- 実績登録 -->
          <template v-slot:[`item.achieve`]="{ item }">
            <v-btn v-if="registRole == 'true'" small @click="moveToAdd(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <!-- 担当者 -->
          <template v-slot:[`item.userNm`]="{ item }">
            <div v-if="item.userNm.startsWith(',')">{{ item.userNm.slice(1) }}</div>
            <div v-else>{{ item.userNm }}</div>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <!-- チェックボックス2件以上選択時ダイアログ -->
      <v-dialog v-model="checkDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ checkMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okcopy"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="sortChangeDialog" :max-width="300" origin:top>
        <!-- 棚卸表ソートのダイアログ -->
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name"> {{ $t("label.lbl_countSortOrder") }}</span>
          </v-card-title>
          <v-form ref="numIssueVali" lazy-validation>
            <v-container>
              <v-row>
                <div class="search-autocomplete first-search-item">
                  <v-autocomplete
                    dense
                    v-model="countSortItem"
                    :items="sortItemList"
                    class="txt-single"
                    persistent-hint
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
                <span class="require">*</span>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="search-row-exeBtn">
              <div class="btn-search-area" style="float: left">
                <v-btn class="other-btn" style="margin-left: 0" @click="dialogClose">
                  {{ $t("btn.btn_close") }}
                </v-btn>
                <v-btn class="post-btn" @click="getInvoiceIssue">
                  {{ $t("btn.btn_print") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ message }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
//import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.SCREEN_ID.P_CNT_003,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // リストのソートダイアログ
    sortChangeDialog: false,
    // チェック済みリスト
    checkedList: [],
    stockCheckDivList: [],
    // 警告ダイアログ
    alertDialog: false,
    // ダイアログ表示メッセージ
    message: "",
    // 棚卸表ソート
    countSortItem: "location_no",
    sortItemList: [
      // ロケーション
      {
        text: i18n.tc("label.lbl_locationNo"),
        value: "location_no",
      },
      // 品番
      {
        text: i18n.tc("label.lbl_productCode"),
        value: "item_cd",
      },
    ],
    // コピー2件以上エラー
    checkDialogMsg: "",
    checkMessage: "",
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    clientSid: "",
    // 棚卸実施日(From)
    countImplementationDateFromCal: "",
    countImplementationDateFrom: "",
    dateMenuFrom: false,
    // 棚卸実施日(To)
    countImplementationDateToCal: "",
    countImplementationDateTo: "",
    dateMenuTo: false,
    // 担当者
    managerSelected: "",
    managerList: [],
    // 倉庫
    warehouseSelected: "",
    warehouseList: [],
    // ロケ
    locationSelected: "",
    locationList: [],
    HeaderlocationGList: [],
    // ソートアイテム
    sortItem: "",
    sortOptions: {},
    // ページング幅
    u_10: false,
    involce: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // メニュー
    openMenu: null,
    // ボタン切り替え
    displayBtn: false,
    // 実績登録権限
    registRole: "true",
    // 承認登録実行権限
    approveRole: "true",
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    checkFlg: false,
    // 検索条件保持
    searchSuppliersSelected: "",
    searchCountImplementationDateTo: "",
    searchCountImplementationDateFrom: "",
    searchWarehouseSelected: "",
    searchManagerSelected: "",
    searchLocationSelected: "",
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      {
        text: "選択",
        value: "check",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // 取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "clientName",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 棚卸実施日
      {
        text: i18n.tc("label.lbl_countImplementationDate"),
        value: "countImplementationDateJ",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 棚卸指示No
      {
        text: i18n.tc("label.lbl_countInstNo"),
        value: "stockNo",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 棚卸方法
      {
        text: i18n.tc("label.lbl_countMethod"),
        value: "stockMethod",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // ロケグループ
      {
        text: i18n.tc("label.lbl_locaGroup"),
        value: "locationGp",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 担当者
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "userNm",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // 登録
      {
        text: i18n.tc("btn.btn_achieveAdd"),
        value: "achieve",
        width: "7%",
        align: "center",
        sortable: false,
      },
    ],
    // 総件数
    totalCount: 0,
    // 一覧
    inputList: [],
    // 入力チェック
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
  }),

  methods: {
    link() {
      location.href = this.returnURL;
    },

    /**
     * 一覧とページングネーションの幅調整処理
     */
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getApiList(false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false);
    },
    // 承認ボタン押下時
    approval() {
      // チェックした行
      const checkList = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          checkList.push(this.inputList[i]);
        }
      }

      // １つもチェックされていない場合、画面遷移できない
      if (checkList.length == 0) {
        this.alertDialog = true;
        this.message = messsageUtil.getMessage("P-EXT-003_001_E");
      } else if (checkList.length == 1) {
        // 画面遷移
        for (let i = 0; i < checkList.length; i++) {
          this.$router.push({
            name: appConfig.SCREEN_ID.P_CNT_006,
            params: {
              checkList: checkList,
              officeSid: checkList[i].officeSid,
              clientSid: checkList[i].clientSid,
              manager: this.managerSelected,
              warehouse: this.warehouseSelected,
              location: this.locationSelected,
              From: this.countImplementationDateFrom,
              To: this.countImplementationDateTo,
              warehouseSid: checkList[i].warehouseSid,
              stockNo: checkList[i].stockNo,
              stockCheckDiv: checkList[i].stockCheckDiv,
              countImplementationDateJ: checkList[i].countImplementationDateJ,
              date: checkList[i].countImplementationDate,
              name: "CountAchieveApproval",
              FromCal: this.countImplementationDateFromCal,
              ToCal: this.countImplementationDateToCal,
              listSearch: {
                // 検索条件保持
                searchSuppliersSelected: this.searchSuppliersSelected,
                searchCountImplementationDateTo: this.searchCountImplementationDateTo,
                searchCountImplementationDateFrom: this.searchCountImplementationDateFrom,
                searchWarehouseSelected: this.searchWarehouseSelected,
                searchManagerSelected: this.searchManagerSelected,
                searchLocationSelected: this.searchLocationSelected,
              },
            },
          });
        }
      } else {
        this.checkDialogMsg = true;
        this.checkMessage = messsageUtil.getMessage("P-EXT-001_001_E");
      }
    },
    okcopy() {
      this.checkDialogMsg = false;
    },

    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },

    getInitData() {
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // 担当者
      const userBizList = getParameter.getUserBiz();
      // 倉庫一覧
      Promise.all([clientList, userBizList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.managerList = result[1];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 倉庫マスタ取得
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: `${row.warehouseLanguage[0].warehouseName}` + `(${row.warehouseCd})`,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // ロケグループ(ヘッダー用)取得
    getHeaderLocationG() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      // 検索区分
      config.params.searchCategoryDiv = "0";
      // 処理区分
      config.params.processDiv = "02";
      // ロケグループダイアログには倉庫SIDが必要
      if (this.warehouseSelected) {
        config.params.warehouseSid = this.warehouseSelected;
      }
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
          .then((response) => {
            // console.debug("getHeaderLocationG() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: `${i18n.tc("label.lbl_locaGroup") + row.locationGroup}`,
                  value: row.locationGroup,
                });
              });
              this.HeaderlocationGList = list;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     *  検索ボタン押下処理 TODO
     */
    getApiList(checkBoxFlag) {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      if (
        // FromToの日付、入力チェック
        new Date(this.countImplementationDateFrom).getTime() >
        new Date(this.countImplementationDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        if (this.$route.params.returnParam && this.checkFlg) {
          // 遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        if (inputCheckResult) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";
          // 初期検索かページング検索か判断
          if (checkBoxFlag) {
            this.isAllSelected = false;
          }
          // 画面遷移フラグをfalseに戻す
          this.checkFlg = false;

          // ローディング画面表示ON
          this.loadingCounter = 1;

          const config = this.$httpClient.createGetApiRequestConfig();
          // 営業所SID（ログインユーザ情報）
          config.params.officeSid = sessionStorage.getItem("sales_office_sid");
          // 取引先SID
          config.params.clientSid = this.suppliersSelected;
          this.clientSid = this.suppliersSelected;
          // 棚卸実施日（From）
          config.params.dtFrom = dateTimeHelper.convertUTC(this.countImplementationDateFrom);
          // 棚卸実施日（To）
          var arr = this.countImplementationDateTo + " 23:59:59.999";
          config.params.dtTo = dateTimeHelper.convertUTC(arr);
          // 削除フラグ(0=未削除)
          config.params.deleteFlg = "0";

          if (this.managerSelected) {
            // 担当者
            config.params.userSid = this.managerSelected;
          }
          if (this.warehouseSelected) {
            // 倉庫
            config.params.warehouseSid = this.warehouseSelected;
          }
          if (this.locationSelected) {
            // ロケ
            config.params.locationGroup = this.locationSelected;
          }

          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageIndex = this.page;
          config.params.reqComPageLimit = this.itemsPerPage;
          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }

          // 検索条件保持
          this.searchSuppliersSelected = this.suppliersSelected;
          this.searchCountImplementationDateTo = this.countImplementationDateTo;
          this.searchCountImplementationDateFrom = this.countImplementationDateFrom;
          this.searchWarehouseSelected = this.warehouseSelected;
          this.searchManagerSelected = this.managerSelected;
          this.searchLocationSelected = this.locationSelected;

          // console.debug("getApiList(checkBoxFlag) Config", config);
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_STOCKCHECKPLAN, config)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // console.debug("getApiList(checkBoxFlag) Response", response);

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  const list = [];

                  // 棚卸計画リストを取得
                  jsonData.resIdv.stockCheckPlan.forEach((row) => {
                    list.push({
                      // 棚卸実施日（API登録用=UTC）
                      countImplementationDate: row.yyyymmddHhmmss,
                      // 棚卸実施日（画面表示用=JST:秒数なし）
                      countImplementationDateJ: dateTimeHelper
                        .convertUTC2JST(row.yyyymmddHhmmss)
                        .substr(0, 10),
                      // 棚卸指示No
                      stockNo: row.stockCheckNo,
                      // 棚卸指示SID
                      stockCheckSid: "2200000074",
                      // 棚卸方式名
                      stockMethod: row.stockCheckName,
                      // 棚卸方式区分
                      stockMethodKbn: row.stockCheckDiv,
                      // ロケグループ
                      locationGp: i18n.tc("label.lbl_locaGroup") + row.locationGroup,
                      // 担当者名
                      userNm: row.userName,
                      // 担当者SID
                      userSid: row.userSid,
                      // 取引先SID
                      clientSid: row.clientSid,
                      // 取引先名
                      clientName: row.clientName,
                      // 有償無償区分
                      freeCostDiv: row.freeCostDiv,
                      // 有償無償名
                      freeCostName: row.freeCostName,
                      // ロケNo
                      locationNo: row.locationNo,
                      // ロケSID
                      locationSid: row.locationSid,
                      // 倉庫名
                      warehouseName: row.warehouseName,
                      // 棚卸実績承認で使用する値
                      officeSid: row.officeSid,
                      warehouseSid: row.warehouseSid,
                      stockCheckDiv: row.stockCheckDiv,
                      // 実績登録権限
                      registRole: this.registRole,
                    });
                  });
                  this.inputList = list;

                  // 検索結果が1件以上あれば、ボタンを表示
                  if (0 < this.inputList.length) {
                    this.displayBtn = true;
                  }

                  // ページング検索の場合の全選択チェックボックス処理
                  if (this.isAllSelected) {
                    this.isAllSelected = true;
                    for (var i = 0; i < this.inputList.length; i++) {
                      this.inputList[i].check = true;
                    }
                  }
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },
    // 棚卸表作成ボタン押下、ダイアログ表示
    clickCreateList() {
      // チェック済リスト
      var stockCheckNo = "";
      this.stockCheckDivList = [];
      this.checkedList = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          stockCheckNo = this.inputList[i].stockNo;
          // 指示No（POST）
          this.checkedList.push(stockCheckNo);
          // 棚卸方式の重複チェック用
          this.stockCheckDivList.push(this.inputList[i].stockMethodKbn);
        }
      }
      // 計画Noが重複のない配列を作る
      this.checkedList.filter((x, i, self) => {
        return self.indexOf(x) === i;
      });
      // 棚卸方式が重複のない配列を作る
      this.stockCheckDivList.filter((x, i, self) => {
        return self.indexOf(x) === i;
      });

      // チェックがあれば、ソートダイアログ表示
      if (this.checkedList.length > 0) {
        this.sortChangeDialog = true;
      } else {
        // なければ警告ダイアログ表示
        this.alertDialog = true;
        this.message = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },
    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },
    // 棚卸表発行ボタン押下後出現ダイアログ閉じる
    dialogClose() {
      this.sortChangeDialog = false;
    },
    // 棚卸表発行API
    getInvoiceIssue() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CNT_003;
      // 指示Noリスト
      body.reqIdv.stockCheckNumbers = this.checkedList;
      // ソート項目
      body.reqIdv.sortItem = this.countSortItem;
      // 棚卸区分
      body.reqIdv.stockCheckDivs = this.stockCheckDivList;
      // 棚卸No＋区分
      let stockCheckNumbersDivs = [];
      for (let i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          stockCheckNumbersDivs.push(this.inputList[i].stockNo + this.inputList[i].stockMethodKbn);
        }
      }
      body.reqIdv.stockCheckNumbersDivs = stockCheckNumbersDivs;
      // 取引先SID
      body.reqIdv.clientSid = this.clientSid;

      // console.debug("getInvoiceIssue() Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.CNT_LISTISSUE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("getInvoiceIssue() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              this.returnURL = jsonData.resIdv.filePath;
              this.link();
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.suppliersSelected = "";
      this.managerSelected = "";
      this.countImplementationDate = "";
      this.warehouseSelected = "";
      this.locationSelected = "";
      this.inputList = [];
      this.countImplementationDateTo = dateTimeHelper.convertJST();
      this.countImplementationDateFrom = dateTimeHelper.convertJST();
      this.countImplementationDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.countImplementationDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getInitData();
      this.getWarehouse();
      this.getHeaderLocationG();
      //遷移先画面で戻るボタンを押下した場合
      if (this.$route.params.returnParam != null && !this.clearFlg) {
        this.suppliersSelected = this.$route.params.returnParam.searchSuppliersSelected;
        this.warehouseSelected = this.$route.params.returnParam.searchWarehouseSelected;
        this.locationSelected = this.$route.params.returnParam.searchLocationSelected;
        this.managerSelected = this.$route.params.returnParam.searchManagerSelected;
        this.countImplementationDateFrom =
          this.$route.params.returnParam.searchCountImplementationDateFrom;
        this.countImplementationDateTo =
          this.$route.params.returnParam.searchCountImplementationDateTo;
        this.changeDateTo(this.countImplementationDateTo);
        this.changeDateFrom(this.countImplementationDateFrom);
        this.checkFlg = true;
        this.getApiList();
      }
      // 実績登録権限を取得
      this.registRole = sessionStorage.getItem("P-CNT-004");
      // 承認登録実行権限を取得
      this.approveRole = sessionStorage.getItem("P-CNT-006");
    },

    // 棚卸実績登録画面へ遷移
    moveToAdd(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.$router.push({
        name: appConfig.SCREEN_ID.P_CNT_004,
        params: {
          // 取引先SID
          clientSid: this.inputList[this.editedIndex].clientSid,
          // 取引先名
          clientName: this.inputList[this.editedIndex].clientName,
          // 棚卸方式区分
          stockMethodKbn: this.inputList[this.editedIndex].stockMethodKbn,
          // 棚卸方式名
          stockMethod: this.inputList[this.editedIndex].stockMethod,
          // 棚卸日（棚卸実施日）
          countImplementationDate: this.inputList[this.editedIndex].countImplementationDate,
          // 棚卸指示No
          stockNo: this.inputList[this.editedIndex].stockNo,
          // 棚卸指示SID
          stockSid: this.inputList[this.editedIndex].stockCheckSid,
          // 有償無償区分
          freeCostDiv: this.inputList[this.editedIndex].freeCostDiv,
          // 有償無償名
          freeCostName: this.inputList[this.editedIndex].freeCostName,
          // ロケグループ
          locationGp: this.inputList[this.editedIndex].locationGp,
          // ロケNo
          locationNo: this.inputList[this.editedIndex].locationNo,
          // ロケSID
          locationSid: this.inputList[this.editedIndex].locationSid,
          // 担当者名
          userNm: this.inputList[this.editedIndex].userNm,
          // 担当者SID
          userSid: this.inputList[this.editedIndex].userSid,
          // 倉庫名
          warehouseName: this.inputList[this.editedIndex].warehouseName,
          // 倉庫SID
          warehouseSid: this.inputList[this.editedIndex].warehouseSid,
          // 画面名
          name: appConfig.SCREEN_ID.P_CNT_003,
          // 選択値（担当者）
          person: this.managerSelected,
          // 選択値（倉庫）
          warehouse: this.warehouseSelected,
          // 選択値（ロケ）
          location: this.location,
          listSearch: {
            // 検索条件保持
            searchSuppliersSelected: this.searchSuppliersSelected,
            searchCountImplementationDateTo: this.searchCountImplementationDateTo,
            searchCountImplementationDateFrom: this.searchCountImplementationDateFrom,
            searchWarehouseSelected: this.searchWarehouseSelected,
            searchManagerSelected: this.searchManagerSelected,
            searchLocationSelected: this.searchLocationSelected,
          },
        },
      });
    },
    /**
     * 棚卸計画登録画面を開きます。
     */
    openUpdateDelete(item) {
      // // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);

      // 修正画面へ渡す値を設定します。
      this.$router.push({
        name: appConfig.SCREEN_ID.P_CNT_002,
        params: {
          // 取引先SID
          clientSid: this.inputList[this.editedIndex].clientSid,
          // 取引先名
          clientName: this.inputList[this.editedIndex].clientName,
          // 棚卸方式区分
          stockMethodKbn: this.inputList[this.editedIndex].stockMethodKbn,
          // 棚卸方式名
          stockMethod: this.inputList[this.editedIndex].stockMethod,
          // 棚卸日（棚卸実施日）
          countImplementationDate: this.inputList[this.editedIndex].countImplementationDate,
          // 棚卸指示No
          stockNo: this.inputList[this.editedIndex].stockNo,
          // 棚卸指示SID
          stockSid: this.inputList[this.editedIndex].stockSid,
          // 有償無償区分
          freeCostDiv: this.inputList[this.editedIndex].freeCostDiv,
          // 有償無償名
          freeCostName: this.inputList[this.editedIndex].freeCostName,
          // ロケグループ
          locationGp: this.inputList[this.editedIndex].locationGp,
          // ロケNo
          locationNo: this.inputList[this.editedIndex].locationNo,
          // ロケSID
          locationSid: this.inputList[this.editedIndex].locationSid,
          // 担当者名
          userNm: this.inputList[this.editedIndex].userNm,
          // 担当者SID
          userSid: this.inputList[this.editedIndex].userSid,
          // 倉庫SID
          warehouseSid: this.inputList[this.editedIndex].warehouseSid,
          // 画面名
          name: "CntInstList",
          // 選択値（担当者）
          person: this.managerSelected,
          // 選択値（倉庫）
          warehouse: this.warehouseSelected,
          // 選択値（ロケ）
          location: this.location,
          listSearch: {
            // 検索条件保持
            searchSuppliersSelected: this.searchSuppliersSelected,
            searchCountImplementationDateTo: this.searchCountImplementationDateTo,
            searchCountImplementationDateFrom: this.searchCountImplementationDateFrom,
            searchWarehouseSelected: this.searchWarehouseSelected,
            searchManagerSelected: this.searchManagerSelected,
            searchLocationSelected: this.searchLocationSelected,
          },
        },
      });
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 棚卸実施日を－１日します。
     */
    prevDate(isFrom) {
      if (isFrom) {
        if (this.countImplementationDateFromCal == null) {
          return;
        }

        let date = new Date(this.countImplementationDateFromCal);
        let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
        if (this.countImplementationDateFromCal != toDate) {
          this.countImplementationDateFromCal = toDate;
        } else {
          this.countImplementationDateFromCal = dateTimeHelper.dateCalc(
            date.setDate(date.getDate() - 1)
          );
        }
      } else {
        if (this.countImplementationDateToCal == null) {
          return;
        }

        let date = new Date(this.countImplementationDateToCal);
        let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
        if (this.countImplementationDateToCal != toDate) {
          this.countImplementationDateToCal = toDate;
        } else {
          this.countImplementationDateToCal = dateTimeHelper.dateCalc(
            date.setDate(date.getDate() - 1)
          );
        }
      }
    },

    /**
     * 棚卸実施日を＋１日します。
     */
    nextDate(isFrom) {
      if (isFrom) {
        if (this.countImplementationDateFromCal == null) {
          return;
        }

        let date = new Date(this.countImplementationDateFromCal);
        let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
        if (this.countImplementationDateFromCal != toDate) {
          this.countImplementationDateFromCal = dateTimeHelper.dateCalc(
            date.setDate(date.getDate() + 2)
          );
        } else {
          this.countImplementationDateFromCal = dateTimeHelper.dateCalc(
            date.setDate(date.getDate() + 1)
          );
        }
      } else {
        if (this.countImplementationDateToCal == null) {
          return;
        }

        let date = new Date(this.countImplementationDateToCal);
        let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
        if (this.countImplementationDateToCal != toDate) {
          this.countImplementationDateToCal = dateTimeHelper.dateCalc(
            date.setDate(date.getDate() + 2)
          );
        } else {
          this.countImplementationDateToCal = dateTimeHelper.dateCalc(
            date.setDate(date.getDate() + 1)
          );
        }
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.countImplementationDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.countImplementationDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.countImplementationDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.countImplementationDateToCal = null;
      }
    },

    /**
     * チェックボックス取得処理
     */
    onInputCheck(item) {
      if (item.check) {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].value = true;
        }
      } else {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = false;
          this.inputList[i].value = false;
        }
      }
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 倉庫リストボックス取得処理
     */
    setWarehouse() {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == this.warehouseSelected) {
          return this.warehouseList[i].name;
        }
      }
    },

    /**
     * 担当者リストボックス変換
     */
    setManager() {
      for (var i = 0; i < this.managerList.length; i++) {
        if (this.managerList[i].value == this.managerSelected) {
          return this.managerList[i].name;
        }
      }
    },

    /**
     * ロケリストボックス変換
     */
    setLocation() {
      for (var i = 0; i < this.HeaderlocationGList.length; i++) {
        if (this.HeaderlocationGList[i].value == this.locationSelected) {
          return this.HeaderlocationGList[i].name;
        }
      }
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 変更検知用の設定
      this.countImplementationDateFromCal = "";
      this.countImplementationDateToCal = "";
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    warehouseSelected(newValue) {
      if (newValue || null) {
        this.warehouseSelected = newValue;
        this.getHeaderLocationG();
      } else {
        this.warehouseSelected = newValue;
        this.getHeaderLocationG();
      }
    },
    dialog(val) {
      val || this.close();
    },

    countImplementationDateFromCal: {
      handler(val) {
        this.countImplementationDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    countImplementationDateToCal: {
      handler(val) {
        this.countImplementationDateTo = this.formatDate(val);
      },
      deep: true,
    },
    page: function (newValue) {
      //画面クリア時の場合
      if (this.clearFlg) {
        this.clearFlg = false;
        return;
      }
      this.page = newValue;
      this.getApiList(false);
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          if (sortBy == "shipNo") {
            sortBy = "outListNo";
          } else if (sortBy == "countImplementationDate") {
            sortBy = "outScheduleDate";
          }
          this.sortItem = sortBy + " " + ascdesc;
          this.getApiList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.link {
  text-decoration: none;
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
</style>
